<template>
  <div class="row">
    <div class="col-12 col-lg-6 c">
      <div class="card">
        <div class="card-header">
          <h4><i class="fa fa-plus"></i> إضافة جهاز</h4>
        </div>
        <div class="card-body">
          <div class="col-12 text-center">
            <img
              :src="image ? image : require('@/assets/images/upload.png')"
              style="width: 100px; height: 100px; cursor: pointer"
            />
            <div id="progress-wrp">
              <div class="progress-bar"></div>
              <div class="status">0%</div>
            </div>
            <br />
            <input
              type="file"
              title="اضغط لتغيير الصورة"
              id="ingredient_file"
            />
          </div>
          <div class="form-group">
            <label for="">العنوان</label>
            <input type="text" class="form-control" v-model="title" />
          </div>
          <div class="form-group">
            <label for="">السعر</label>
            <input type="number" class="form-control" v-model="price" />
          </div>
          <div class="form-group">
            <label for="">التفاصيل</label>
            <textarea
              class="form-control"
              v-model="details"
              rows="3"
            ></textarea>
          </div>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            class="g"
            inline
            :value="true"
            v-model="available"
          >
            مُتاح
          </b-form-checkbox>
          <div class="col-12 text-center g">
            <button class="btn btn-success" @click="add()">
              إضافة الجهاز <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BFormCheckbox,
    ToastificationContent,
  },
  data() {
    return {
      title: null,
      price: 0,
      image: null,
      details: null,
      available: true,
    };
  },
  created() {
    var g = this;
    var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("jwt", user.jwt);
      $.ajax({
        type: "POST",
        url: api + "/console/devices/upload",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          $("#progress-wrp").fadeOut("slow");
          g.image = data.response;
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
  },
  methods: {
    add() {
      var g = this;
      if (g.title && g.image && g.details && g.price) {
        $.post(api + "/console/devices/add", {
          jwt: user.jwt,
          title: g.title,
          image: g.image,
          price: g.price,
          details: g.details,
          available: g.available,
        }).then(function () {
          g.title = null;
          g.details = null;
          g.image = null;
          g.price = 0;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "تم بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        });
      } else {
        alert("هناك حقول مطلوبة");
      }
    },
  },
};
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>